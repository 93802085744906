<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O distrito de Águas Claras pertence ao município de Mariana e
                possui como subdistritos
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_campinas' }"
                  >Campinas</router-link
                >
                e
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_pedras' }"
                  >Pedras</router-link
                >, que por sua vez tem
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_borba' }"
                  >Borba</router-link
                >
                como povoamento. Situa-se a cerca de 40 km da sede de Mariana e
                a 155 km de Belo Horizonte, capital do estado de Minas Gerais.
              </p>
              <p>
                O dia 24 de agosto é especial para a comunidade de Águas Claras,
                que comemora a data de seu padroeiro, São Luiz Rei de França. A
                partir de 2015, essa data também ficou marcada pela aprovação da
                lei que elevou Águas Claras à categoria de distrito. Após
                consulta pública aos moradores, Campinas e Pedras decidiram
                integrar o novo distrito. No período colonial, enquanto a sede
                de Mariana se fixava nas margens do Ribeirão do Carmo, outros
                povoados se formaram em terras mais distantes, ao norte do Rio
                Gualaxo do Norte. Entre eles Águas Claras do Lobo (atual
                distrito de Águas Claras), bem como o Inficcionado (atual Santa
                Rita Durão) e Paulo Moreira (atual município de Alvinópolis).
                <sup>1</sup>
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_aguas_claras_01.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista do campo de futebol de Águas Claras, com o relevo montanhoso
            ao fundo. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_aguas_claras_02.png"
          />
          <p style="font-size: 0.6em; text-align: center;">
            Vista interna da quadra poliesportiva. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O relevo dessa região é caracterizado por topografia montanhosa,
                destacando-se na paisagem os afloramentos rochosos em meio à
                cobertura vegetal de matas e campos de pastagens. A ocupação da
                comunidade ocorre de maneira longitudinal na direção leste e
                oeste predominantemente, sendo a área central circundante ao
                espaço da quadra de futebol, tendo a oeste a igreja em louvor a
                São Luís Rei de França. Ao redor do campo, encontra-se ainda a
                escola municipal e concentram-se estabelecimentos comerciais e
                de serviços como restaurantes, pousadas e mercearias. O gabarito
                das construções é, em sua maioria, de um pavimento, e algumas
                assobradadas, implantadas em terrenos com amplos quintais onde
                são cultivadas hortas e árvores frutíferas em meio à criação de
                animais.
              </p>
              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira <sup>2</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> As informações a respeito do histórico e da
              caracterização de Águas Claras tiveram como base principal o
              Diagnóstico preliminar dos bens culturais identificados no
              território atingido em Mariana pelo rompimento da barragem de
              Fundão, elaborado em 2017 pela empresa Pólen Consultoria
              Patrimônio e Projetos Ltda. O Diagnóstico foi encomendado pela
              Comissão dos Atingidos e pela Cáritas Brasileira MG e juntamente
              com os dados do cadastro realizado pela Cáritas, possibilitou um
              entendimento mais amplo das relações culturais, sociais e
              econômicas que foram consolidadas nesse território ao longo dos
              séculos. O trabalho abrange aspectos geográficos, históricos,
              arqueológicos, arquitetônicos, antropológicos e museológicos.
              Contudo, ainda se trata de um estudo e diagnóstico preliminar,
              sendo necessário a ampliação do conhecimento sobre as comunidades
              atingidas.
            </p>
            <p>
              <sup>2</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
h6 {
  font-size: 0.8em;
}

section {
  margin-bottom: 3em;
}
.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.table__section {
  margin-top: 3em;
  margin-bottom: 3em;
}

#app
  > div
  > main
  > div
  > div
  > div.container
  > section
  > div
  > div
  > div.v-data-table.theme--light
  > div
  > table
  > thead
  > tr
  > th {
  color: black;
  font-size: 0.6em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
